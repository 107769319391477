import { getUsSantaElisaFlxsData } from "../usSantaElisa/usSantaElisaFlxsData";
import { getUsBatataisFlxsData } from "../usBatatais/usBatataisFlxsData";
import { getUsSonoraFlxsData } from "../usSonora/usSonoraFlxsData";
import { getUsMonteAlegreFlxsData } from "../usMonteAlegre/usMonteAlegreFlxsData";

export function getFlxData(area, screen, data, site){
    // console.log('getFlxData', area, screen, data, site)
    switch (site){
        case 'usSantaElisa': return getUsSantaElisaFlxsData(area, screen, data)
        case 'usBatatais': return getUsBatataisFlxsData(area, data)
        case 'usSonora': return getUsSonoraFlxsData(area, data)
        case 'usMonteAlegre': return getUsMonteAlegreFlxsData(area, data)
        default: return null
    }
}