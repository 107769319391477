import React, {useContext, useState, useRef, useEffect } from 'react';
import PSContext from '../opoio/PSContext';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '../../components/BarraSuperior/Modal';
import Headlandscape from '../../components/ui/HeadLandscape';
import BodyCompRevs from './bodyCompRevs';
import { FaRegFilePdf } from "react-icons/fa6";
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import GlobalStateContext from '../../components/GlobalStateContext';
import ChartsCompRevs from './chartsCompRevs';

export default function BtExportPdfCompPS({disable}) {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const {psData1, psData2, setPsIsPrinting} = useContext(PSContext)
  const {newAction} = AnalyticsHooks()
  const {site, user} = useContext(GlobalStateContext)

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

    async function exportPDF(){
      newAction('expPDFCompPS', site, user.email || '')
      setPsIsPrinting(true)
      setIsLoading(true)
      setProgress(0)
      setModalText('Gerando PDF...')
      let progressValue = 0;
      const interval = setInterval(() => {
        progressValue += 5;
        if (progressValue <= 100) {
          setProgress(progressValue)
        } else {
          clearInterval(interval)
          setIsLoading(false)
          setPsIsPrinting(false)
        }
      }, 8);
     
      const pdf = new jsPDF('l', 'mm', 'a4', true);
      
      let promiseChain = Promise.resolve();
  
      divRefs.current.forEach((div, index) => {
        
        promiseChain = promiseChain.then(async () => {
          const canvas = await html2canvas(div);
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 277; // largura em mm para A4
          const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          if (index < divRefs.current.length - 1){
            pdf.addPage("a4", "l");
          }
        })
        
      });
  
      promiseChain.then(() => {
        pdf.save(`Comparativo PS ${psData2['MIX'][0].safra}-${psData2['MIX'][0].revisao} x ${psData1['MIX'][0].safra}-${psData1['MIX'][0].revisao}.pdf`)
      }).catch(err => {
        console.error('Erro ao gerar PDF:', err);
      });
    
    };

    const pages = [
        {'MATÉRIA PRIMA':[0,100], 'UPTIME':[0,100], 'MOAGEM':[0,6]},
        {'MIX':[0,100], 'EFICIÊNCIAS':[0,6], 'PERDAS':[0,4]},
        {'PERDAS':[5,100], 'AÇÚCAR':[0,100], 'ETANOL':[0,2]},
        {'ETANOL':[3,100], 'ENERGIA':[0,100]},
        {'VAPOR':[0,100], 'BIOMASSA':[0,100]},
        {'DIAS NÃO EFETIVOS':[0,100]}
    ]

  return (
    <div className='psMenuButtom' >
        {psData1 && psData2 &&
            <div style={{ position: 'absolute', left: '-9999px' , top: '9999px'}}>
                {pages.map((page, index) => (
                        <div key={index} ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
                        <Headlandscape title='COMPARATIVO REVISÕES' 
                            subtitle='' 
                            fileName={`${psData2['MIX'][0].safra} - ${psData2['MIX'][0].revisao} x ${psData1['MIX'][0].safra} - ${psData1['MIX'][0].revisao}`}
                            nomeField='' page={`${index+1}`} pages={`${pages.length+2}`}/>
                        <div style={{height: '630px', width: '1000px', left: '0', top: '0', position: 'relative',  lineHeight: '1' }}>
                            <BodyCompRevs secs={page}/>
                        </div>
                    </div>
                ))}
                <></>
                <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
                <Headlandscape title='COMPARATIVO REVISÕES' 
                    subtitle=''
                    fileName={`${psData2['MIX'][0].safra} - ${psData2['MIX'][0].revisao} x ${psData1['MIX'][0].safra} - ${psData1['MIX'][0].revisao}`}
                    nomeField='' page='7' pages='8'/>
                <div style={{height: '630px', width: '1000px', left: '0', top: '0', position: 'relative', lineHeight: '1' }}>
                  <ChartsCompRevs page='PAGE1' h='210px'/>
                </div>
            </div>
            <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
                <Headlandscape title='COMPARATIVO REVISÕES' 
                    subtitle='' 
                    fileName={`${psData2['MIX'][0].safra} - ${psData2['MIX'][0].revisao} x ${psData1['MIX'][0].safra} - ${psData1['MIX'][0].revisao}`}
                    nomeField='' page='8' pages='8'/>
                <div style={{height: '630px', width: '1000px', left: '0', top: '0', position: 'relative', lineHeight: '1' }}>
                <ChartsCompRevs page='PAGE2' h='210px'/>
                </div>
                </div>
            </div>}

        <div>
            <button className='psButtom' onClick={exportPDF} disabled={disable}>
                <FaRegFilePdf  className='psMenuIcon'/>
                <div className='psMenuText'>EXPORTAR</div>
            </button>
            <Modal show={isLoading} progress={progress} texto={modalText}/>
        </div>
    </div>
  )
}



