import React, { useContext } from 'react'
import BtNewPS from "./botoes/btNewPS";
import BtSaveAsPS from './botoes/btSaveAsPS';
import BtEditPS from './botoes/btEditPS';
import BtOpenPS from './botoes/btOpenPS';
import BtExportPdfPS from './botoes/btExportPdfPS';
import BtChartsPS from './botoes/btChartsPS';
import PSContext from './opoio/PSContext';

function MenuPlanoSafra() {
  const { psData } = useContext(PSContext)
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="psMainMenu">
                <BtOpenPS/>
                <BtNewPS/>
                <BtSaveAsPS/>
                <BtEditPS/>
                <BtChartsPS/>
                <BtExportPdfPS/>
            </div>
            {psData && <div style={{marginRight: '3vh', alignContent: 'center', fontSize: '2vh', fontWeight: 'bold'}}>{psData[0].safra} - {psData[0].revisao}</div>}
         </div> 
    )
}

export default MenuPlanoSafra