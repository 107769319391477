import { useContext } from "react"
import GlobalStateContext from "../../../components/GlobalStateContext"
import psCalcsUBT from "../usBatatais/Plano Safra/psCalcsUBT"
import psCalcsSEL from "../usSantaElisa/Plano Safra/psCalcsSEL"
import psCalcsSON from "../usSonora/Plano Safra/psCalcsSON"
import psCalcsUMA from "../usMonteAlegre/Plano Safra/psCalcsUMA"


export function usePsCalcs(){
    const {site} = useContext(GlobalStateContext)
    switch (site){
        case 'usSantaElisa': return psCalcsSEL()
        case 'usBatatais': return psCalcsUBT()
        case 'usSonora': return psCalcsSON()
        case 'usMonteAlegre': return psCalcsUMA()
        default: 
            return psCalcsUBT()
    }
}