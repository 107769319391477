import PsChartsDataSEL from "../usSantaElisa/Plano Safra/PsChartsDataSEL"
import PsChartsDataUBT from "../usBatatais/Plano Safra/PsChartsDataUBT"
import PsChartsDataSON from "../usSonora/Plano Safra/PsChartsDataSON"
import PsChartsDataUMA from "../usMonteAlegre/Plano Safra/PsChartsDataUMA"

export async function getPsChartsData(site){
    switch (site){
        case 'usSantaElisa': 
            return PsChartsDataSEL
        case 'usBatatais': 
            return PsChartsDataUBT
        case 'usSonora': 
            return PsChartsDataSON
        case 'usMonteAlegre': 
            return PsChartsDataUMA
        default: return null
    }
}