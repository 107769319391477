import React, { useState, useContext } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList'
import { iptValue } from '../../Resources/setNumber'

function InputLineMin({cod, desc}) {
  
  const [prevV, setPrevV] = useState('')
  const { data, setlistAlerts, user, screen, setData, site } = useContext(GlobalStateContext);
  const [v, setV] = useState(iptValue(data[cod]?.limiteMin))

  const handleIpt = (e) => {
    setV(e.target.value)
  }
  const updateValue = async (e) => {
    if (v === '' || !contemNumero(v)){
      setV(prevV)
      
    }else{
      if (v !== prevV){
        setV(e.target.value)
        console.log(''+e.target.value + ': '+e.target.id)
        await fetch(''+global.endPoint+'/'+site+'/setMin', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({codigo: cod, newValue: parseFloat(v)})
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro de requisição.');
        }else{
          console.log('updated: ')
          setData(prevData => ({
            ...prevData,
            [cod]: {
              ...prevData[cod],
              limiteMin: parseFloat(v),
            },
          }));
          // data[cod].limiteMin = parseFloat(v)
          
          setlistAlerts(getAlertList(data))
        }
      })
      .catch(error => {
        // Lida com o erro da requisição
      })
      }
    }
    
  }
  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  return (
    <div className={`inputLineCont`}>
            <div className='line'>
                <div className='desc'>{desc}</div>
                    <input className='ipt' value={v} id={`${screen}-${cod}`} onChange={handleIpt} 
                    onBlur={updateValue} onFocus={handlePrevV} type="text" />
                <div className='unit'>{data[cod]?.unidade}</div>
            </div>
    </div>
  )
}

export default InputLineMin

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}