import logoSEL from '../usSantaElisa/logoSEL.png'
import logoUBT from '../usBatatais/logoUBT.png'
import logoSON from '../usSonora/logoSON.png'
import logoUMA from '../usMonteAlegre/logoUMA.png'

export function getLogo(site){
    switch (site){
        case 'usSantaElisa': return logoSEL
        case 'usBatatais': return logoUBT
        case 'usSonora': return logoSON
        case 'usMonteAlegre': return logoUMA
        default: return null
    }
}