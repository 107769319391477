import { stdPlanoVarsUBT } from '../usBatatais/Plano Safra/stdPlanoSafraUBT'
import { stdPlanoVarsSEL } from '../usSantaElisa/Plano Safra/stdPlanoSafraSEL'
import { stdPlanoVarsSON } from '../usSonora/Plano Safra/stdPlanoSafraSON'
import { stdPlanoVarsUMA } from '../usMonteAlegre/Plano Safra/stdPlanoVarsUMA'  

export function getStdPlanoSafra(site){
    switch (site){
        case 'usSantaElisa': return stdPlanoVarsSEL
        case 'usBatatais': return stdPlanoVarsUBT
        case 'usSonora': return stdPlanoVarsSON
        case 'usMonteAlegre': return stdPlanoVarsUMA
        default: return stdPlanoVarsSEL
    }
}