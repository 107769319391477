import React, { useState, useContext, useEffect } from 'react';
import '../../css/InputLine.css';
import GlobalStateContext from '../GlobalStateContext';
import { getAlertList } from '../../Funcoes/GetAletrsList';
import ModalUpdateValue from '../ui/ModalUpdateValue';

function InputLine({ cod }) {
  const [prevV, setPrevV] = useState('');
  const { setAlert, data, setlistAlerts, user, screen, setData } = useContext(GlobalStateContext);
  const [v, setV] = useState('');

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (data && data[cod]?.valor !== undefined && data[cod]?.valor !== null) {
      setV(data[cod].valor.toFixed(getN()));
    } else {
      setV('0');
    }
  }, [data, cod]);

  function getN() {
    let valor = data[cod]?.valor;
    if (valor == valor.toFixed(0)) {
      return 0;
    } else if (valor == valor.toFixed(1)) {
      return 1;
    } else if (valor == valor.toFixed(2)) {
      return 2;
    } else if (valor < 1) {
      return 3;
    } else if (valor < 100) {
      return 2;
    } else {
      return 1;
    }
  }

  const handleAlert = () => {
    setAlert(true);
  };

  const handleIpt = (e) => {
    setV(e.target.value);
  };

  const updateValue = async (e) => {
    if (v === '' || !contemNumero(v)) {
      setV(prevV);
    } else {
      if (v !== prevV) {
        setShowModal(true);
        setV(e.target.value);
        handleAlert(true);
        // console.log('' + e.target.value + ': ' + e.target.id);
        // await fetch('' + global.endPoint + '/' + user?.uid + '/setValue', {
        //   method: 'PUT',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ codigo: cod, newValue: parseFloat(v) }),
        // })
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw new Error('Erro de requisição.');
        //     } else {
              console.log('updated: ');
              setData((prevData) => ({
                ...prevData,
                [cod]: {
                  ...prevData[cod],
                  valor: parseFloat(v),
                },
              }));
              setShowModal(false);
              setlistAlerts(getAlertList(data));
          //   }
          // })
          // .catch((error) => {
          //   setShowModal(false);
          //   // Lida com o erro da requisição
          // });
      }
    }
  };

  const handlePrevV = (e) => {
    setPrevV(e.target.value);
  };

 if (data){
  return (
    <div
      className={`inputLineCont`}
    >
      <div className='line'>
      <div className='desc'>{data[cod]?.descricao}</div>
      <input
        className={`ipt ${
          data[cod]?.limiteMax !== null && data[cod]?.valor > data[cod]?.limiteMax || data[cod]?.limiteMin !== null && data[cod]?.valor < data[cod]?.limiteMin
            ? 'outOfLimit'
            : ''
        }`}
        value={v ?? ''}
        id={`${screen}-${cod}`}
        onChange={handleIpt}
        onBlur={updateValue}
        onFocus={handlePrevV}
        type='text'
      />
      <div className='unit'>{data[cod]?.unidade}</div>
    </div>
      <ModalUpdateValue show={showModal}>
        <p>Atualizando...</p>
      </ModalUpdateValue>
    </div>
  );
 }else{
  return (
    <></>
  )
 }
}

export default InputLine;

function contemNumero(string) {
  return !isNaN(parseFloat(string)) && isFinite(string);
}
