import defBmeDataSEL from '../usSantaElisa/defBmeDataSEL.json'
import defBmeDataUBT from '../usBatatais/defBmeDataUBT.json'
import defBmeDataSON from '../usSonora/defBmeDataSON.json'
import defBmeDataUMA from '../usMonteAlegre/defBmeDataUMA.json'

export async function getDefData(site){
    switch (site){
        case 'usSantaElisa': return defBmeDataSEL
        case 'usBatatais': return defBmeDataUBT
        case 'usSonora': return defBmeDataSON
        case 'usMonteAlegre': return defBmeDataUMA
        default: return null
    }
}

