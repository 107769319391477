import React, { useState, useContext, useEffect } from 'react'
import MenuBtDivider from '../../components/ui/menuBtDivider';
import GlobalStateContext from '../../components/GlobalStateContext';
import BtCalcularBME from './btCalcularBME';
import PrintAllFlxs from './PrintAllFlxs'
import FileSaveAs from './FileSaveAs';
import FileUploader from './FileUploader';

function MenuBME() {
  const {user, menu, area, bmeFileName} = useContext(GlobalStateContext)
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '2vh', marginBottom: '-0.6vh', marginTop: '-0.4vh'}}>
            <div className="psMainMenu">

                <BtCalcularBME/>
                {area === 'resumoProd' &&
                <>
                <MenuBtDivider/>
                <FileSaveAs/>
                <MenuBtDivider/>
                <FileUploader/>
                <MenuBtDivider/>
                <PrintAllFlxs/>
                </>}
            </div>
            <div style={{padding: '2.5vh', marginRight: '2vh',  fontWeight: 'bold', fontSize: '2vh'}}>{bmeFileName}</div>
         </div> 
    )
}

export default MenuBME