import { getScreenDataUsSantaElisa } from "../usSantaElisa/usSantaElisaScreensData"
import { getScreenDataUsBatatais } from '../usBatatais/usBatataisScreensData'
import { getScreenDataUsSonora } from "../usSonora/usSonoraScreensData"
import { getScreenDataUsMonteAlegre } from '../usMonteAlegre/usMonteAlegreScreensData'  
    

export function getScreenData(area, screen, data, site){
  switch (site){
    case 'usSantaElisa': return getScreenDataUsSantaElisa(area, screen, data)
    case 'usBatatais': return getScreenDataUsBatatais(area, screen, data)
    case 'usSonora': return getScreenDataUsSonora(area, screen, data)
    case 'usMonteAlegre': return getScreenDataUsMonteAlegre(area, screen, data)
    default: 
      return getScreenDataUsSantaElisa(area, screen, data)
  }
}

