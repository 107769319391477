import React, { useContext } from 'react';
import GlobalStateContext from '../../../components/GlobalStateContext';
import MenuAnalises from '../usSantaElisa/AnaliseProducao/menuAnalises';
import AnalisesContext from '../../../ANALISES/AnalisesContext';
import ExcelReader from '../usSantaElisa/AnaliseProducao/ExcelReader';
import DayAnalyzer from '../usSantaElisa/AnaliseProducao/DayAnalizer';

//Batatais
import MenuAnalisesUBT from '../usBatatais/AnaliseProducao/menuAnalises'
import ExcelReaderUBT from '../usBatatais/AnaliseProducao/ExcelReader'
import DayAnalyzerUBT from '../usBatatais/AnaliseProducao/DayAnalizer'
import DayAnalyzerBltXPlano from '../usBatatais/AnaliseProducao/DayAnalizerBltxPlano';

//Sonora

const AnaliseProducao = () => {
    const {site} = useContext(GlobalStateContext)
    const { showImportBoletim, showAnaliseDia, showBltxPlano } = useContext(AnalisesContext)

    switch(site){
        case 'usSantaElisa':
            return (
                <div>
                    <MenuAnalises/>
                    {showImportBoletim &&
                    <ExcelReader/>}
                    {showAnaliseDia &&
                    <DayAnalyzer/>}
                </div>
            )
        case 'usBatatais':
            return (
                <div>
                    <MenuAnalisesUBT/>
                    {showImportBoletim &&
                    <ExcelReaderUBT/>}
                    {showAnaliseDia &&
                    <DayAnalyzerUBT/>}
                    {showBltxPlano &&
                    <DayAnalyzerBltXPlano/>}
                </div>
            )
        default: return (<></>)
    }

    
}

export default AnaliseProducao