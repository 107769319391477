import React, { PureComponent, useContext, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { stdValue } from '../../Resources/setNumber';
import PSContext from '../opoio/PSContext';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value, isPrint } = this.props;

    return (
      <text x={x} y={y} dy={-8} fill={'gray'} textAnchor="middle" fontSize={`${isPrint ? '10px' : '1.4vh'}`} fontWeight={`${isPrint ? 'normal' : 'bold'}`}>
        {stdValue(value)}
      </text>
    );
  }
}

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload, isPrint } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={isPrint ? '16px' : '1.5vh'} textAnchor="middle" fill="gray" transform="rotate(0)" >
            {payload.value}
          </text>
        </g>
      );
    }
  }

const CustomDot = (props) => {
  const { cx, cy, value, payload, stroke } = props;

  // Se o valor for zero ou null, não renderize o ponto
  if (value === 0 || value === null) {
    return null;
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r={4}
      stroke={stroke}
      strokeWidth={0.5}
      fill={payload.isReal ? stroke : 'white'}
    />
  );
};



export default function PsLineChart({psData, cod, color, h = '30vh'}) {
const [chartData, setChartData] = useState(null)
const [varDesc, setVarDesc] = useState('')
const [chartTitle, setChartTitle] = useState('')
const {psIsPrinting} = useContext(PSContext)



function getMax(data){
  if (data){
    const maiorValorDinamico = Math.max(...Object.keys(data)
      .filter(key => ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'].includes(key))
      .map(key => data[key]))
  return maiorValorDinamico
  }else{
    return null
  }
}

useEffect(() => {
    async function fetchData() {
        let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
        console.log('Line Chart', psData, cod)
        let tempdata = psData?.filter(item => item.codigo === cod)[0]
        console.log('Line Chart', tempdata, tempdata, cod)
        let max = getMax(tempdata)
        let tempData2 = []
        setVarDesc(`${tempdata?.descricao} [${tempdata?.unidade}]`)
        setChartTitle(`${tempdata?.descricao} [${max > 99999.999 ? 'k' : ''}${tempdata?.unidade}]`)
        for (const month in months){
            let dblValue = max > 99999.999 ? getValue(tempdata, months[month])/1000 : getValue(tempdata, months[month])
            let newItem = {name: months[month], valor: dblValue, isReal: checkReal(psData, months[month])}
            tempData2.push(newItem)
        }
        setChartData(tempData2)
    }

    if (cod !== ''){
      fetchData();
    }
  }, [cod]);

  function getValue(data, mes){
    switch (mes){
      case 'jan': return data?.jan
      case 'fev': return data?.fev
      case 'mar': return data?.mar
      case 'abr': return data?.abr
      case 'mai': return data?.mai
      case 'jun': return data?.jun
      case 'jul': return data?.jul
      case 'ago': return data?.ago
      case 'set': return data?.set
      case 'out': return data?.out
      case 'nov': return data?.nov
      case 'dez': return data?.dez
      default: return 0
    }
  }

  const formatTooltipValue = (value, name, props) => {
    return [`${stdValue(value)}`, name]; 
  };

  const formatYAxis = (tickItem) => {
    return `${stdValue(tickItem)}`;
  };

  if (cod === '' || !chartData || chartData.length === 0){
   return (<></>)
  }else{
    return (
        <div style={{height: h, width: '100%', borderRadius: '2%', backgroundColor: 'white', margin: '1vh'}}>
          <div style={{marginTop: '0.8vh', color: '#666', fontWeight: 'bold', fontSize: '1.6vh'}}>{chartTitle}</div>
        <ResponsiveContainer width="94%" height="90%">
          
        <LineChart width={500} height={200} data={chartData}
        margin={{ top: 15, right: 15, left: 20, bottom: 20 }} style={{fontSize:'1.5vh', padding: '1vh'}}>
        <CartesianGrid strokeDasharray="2 3" />
        <XAxis dataKey="name" axisLine={false} tickLine={false}  height={psIsPrinting ? 40 : 20} tick={<CustomizedAxisTick isPrint={psIsPrinting}/>} interval={0}/>
        <YAxis axisLine={false} tickLine={false} width={40} tickFormatter={formatYAxis} />
        <Tooltip formatter={formatTooltipValue}/>
        
        {/* <Legend/> */}
        <Line
  type="monotone"
  dataKey="valor"
  name={varDesc}
  stroke={color}
  label={<CustomizedLabel isPrint={psIsPrinting} />}
  animationDuration={250}
  dot={<CustomDot stroke={color} />}
  strokeWidth={2}
  // Adicione a lógica para ignorar valores zero
  connectNulls={true} // conecta os pontos, mesmo que haja null
  isAnimationActive={true}
  data={chartData.map(item => ({ ...item, valor: item.valor === 0 ? null : item.valor }))}
/>

      </LineChart>
     </ResponsiveContainer>
     </div>
     
    )
  }
 
}

function checkReal(psData, mes){
    let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    let revisao = psData[0].revisao
    if (revisao === '13_Realizado'){
      return true
    }
    let month = revisao.slice(-3).toLowerCase();
    let index = months.indexOf(month);
    let index1 = months.indexOf(mes);
    if (index1 < index){
      return true
    }else{
      return false
    }
  }