import React, { useContext, useState, useRef, useEffect} from 'react'

import '../css/planoSafra.css';
import GlobalStateContext from '../components/GlobalStateContext'
import PSContext from './opoio/PSContext';

import { UseColors } from '../components/ui/colors';
import { getStdPlanoSafra } from '../BME/Unidades/Geral/getStdPlanoSafra';
import { usePsCalcs } from '../BME/Unidades/Geral/usePsCalcs';
import { stdValue } from '../Resources/setNumber';

function PsTest({sections}) {
  const {isOpen, psData, editingMonth} = useContext(PSContext)
  const {data} = useContext(GlobalStateContext)
  const newItemRef = useRef(null);
  const psCalcs = usePsCalcs()
  const [groupData, setGroupData] = useState(null)
  const [headData, setHeadData] = useState(getHeadData())
  const stdPlanoVars = getStdPlanoSafra()

  const vh = window.innerHeight
  const ideal = 727

  const COLORS = UseColors()
  
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
  }, []);

  useEffect(() => {
    console.log('Update Plano Safra Screen')
    if (psData){
      const groupedItems = psData.reduce((acc, item) => {
        if (stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show !== false){
          
          if (sections.includes(item.secao)){
            if (!acc[item.secao]) {
              acc[item.secao] = [];
          }
            acc[item.secao].push(item);
          }
          
        }
        return acc;
    }, {});
    
    setGroupData(groupedItems)
    setHeadData(getHeadData())
    
    } 
   
    
  }, [psData, editingMonth]);

  let months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']


  function getHeadData(){
    let psAlerts = psData?.filter(item => item.codigo === 'psAlerts')

    if (psAlerts && psData){
      
      let alerts = [
        {status: psCalcs.checkReal(psData, 'jan') ? 'Real' : 'Plano', mes: 'Jan', alert: psAlerts[0]?.jan === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'fev') ? 'Real' : 'Plano', mes: 'Fev', alert: psAlerts[0]?.fev === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mar') ? 'Real' : 'Plano', mes: 'Mar', alert: psAlerts[0]?.mar === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'abr') ? 'Real' : 'Plano', mes: 'Abr', alert: psAlerts[0]?.abr === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mai') ? 'Real' : 'Plano', mes: 'Mai', alert: psAlerts[0]?.mai === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jun') ? 'Real' : 'Plano', mes: 'Jun', alert: psAlerts[0]?.jun === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jul') ? 'Real' : 'Plano', mes: 'Jul', alert: psAlerts[0]?.jul === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'ago') ? 'Real' : 'Plano', mes: 'Ago', alert: psAlerts[0]?.ago === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'set') ? 'Real' : 'Plano', mes: 'Set', alert: psAlerts[0]?.set === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'out') ? 'Real' : 'Plano', mes: 'Out', alert: psAlerts[0]?.out === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'nov') ? 'Real' : 'Plano', mes: 'Nov', alert: psAlerts[0]?.nov === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'dez') ? 'Real' : 'Plano', mes: 'Dez', alert: psAlerts[0]?.dez === 1 ? false : true}
      ]
      return alerts
    }else{
      let blkAlerts = [
        {status: 'Plano', mes: 'Jan', alert: true},
        {status: 'Plano', mes: 'Fev', alert: true},
        {status: 'Plano', mes: 'Mar', alert: true},
        {status: 'Plano', mes: 'Abr', alert: true},
        {status: 'Plano', mes: 'Mai', alert: true},
        {status: 'Plano', mes: 'Jun', alert: true},
        {status: 'Plano', mes: 'Jul', alert: true},
        {status: 'Plano', mes: 'Ago', alert: true},
        {status: 'Plano', mes: 'Set', alert: true},
        {status: 'Plano', mes: 'Out', alert: true},
        {status: 'Plano', mes: 'Nov', alert: true},
        {status: 'Plano', mes: 'Dez', alert: true}
      ]
      return blkAlerts
    } 
  } 


  return (
    <>
    <div>
        {groupData && !isOpen && <div className='psTableHead' style={{paddingTop: '0px', paddingBottom: '2px', fontSize: '10px', height: '30px'}}>
            <div style={{width: '15%', textAlign: 'left'}}>Descrição</div>
            <div style={{width: '5%', textAlign: 'left'}}>Un.</div>
            {headData.map((item, index) => {
                return(
                    <div className='psHeadElement' style={{width: '5%'}} key={item.mes}>
                        <div>{item.status}</div>
                        <div>{item.mes}</div>
                    </div>
                )
            })}
            <div style={{width: '10%', textAlign: 'center'}}>Acumulado</div>
        </div>}
        </div>
        <div className='psBody'>
        {groupData && !isOpen && Object.keys(groupData).map(group => {
          return(
            <div key={group}>
              {group !== 'settings' && <div className='psSectionHead' style={{fontSize: '10px', padding: '4px 0', paddingLeft: '4px', paddingTop: '8px', color: 'black'}}>{group}</div>}
              {groupData[group].map((item, index) => {
                if (item.codigo === 'psAlerts' || stdPlanoVars.filter(row => row.cod === item.codigo)[0]?.show === false ){
                  return(<></>)
                }
                  return(
                    <div className={`psLine ${index%2 === 0 && "psLineImp"}` } style={{fontSize: '12px', height: 'auto', padding: '4px', color: 'black', fontWeight: 'light'}}>
                    <div style={{width: '15%', textAlign: 'left'}}>{item.descricao}</div>
                    <div style={{width: '5%', textAlign: 'left'}}>{item.unidade}</div>

                    {months.map(mes => {
                        return  <div style={{width: '5%'}} key={mes}>{stdValue(item[mes])}</div>
                    })}
                    <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
                  </div>
                  )
              })}
            
            </div>
          )
        })}
        </div>
    
    </>
  )
}

export default PsTest


