import React, {useContext, useState } from 'react';
import PSContext from '../opoio/PSContext';
import { listaSafras, listaRevs } from '../listasPlanoSafra';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
// import { stdPlanoVars } from '../stdPlanoSafra'
import ModalAlert from '../../components/ui/ModalAlert';
import GlobalStateContext from '../../components/GlobalStateContext';
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import PsHooks from '../../Hooks/psHooks';

export default function SubMenuNew() {
  const {site, user} = useContext(GlobalStateContext)
  const {setAllPsData} = useContext(PSContext)
  const [selectedSafra, setSelectedSafra] = useState(listaSafras[0]);
  const [selectedOption, setSelectedOption] = useState('opcao1');
  const [selectedRev, setSelectedRev] = useState(listaRevs[0]);
  const [revName, setRevName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const {newAction} = AnalyticsHooks()
  const { newPS, resumeAllPS } = PsHooks()
  const stdPlanoVars = getStdPlanoSafra(site)

  const handleChangeSafra = (event) => {
    setSelectedSafra(event.target.value);
  };

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChangeRev = (event) => {
    setSelectedRev(event.target.value);
  };

  const handleChangeName = (event) => {
    setRevName(event.target.value);
  };

  // const handleOpenModal = () => {
  //   setShowModal(true);
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  async function criarPS(){
    newAction('novoPS', site, user.email || '')
    let ps = []
    let rev = selectedOption === 'opcao1' ? selectedRev : revName
        for (const item of stdPlanoVars){
            if (item.show !== false){
                let newItem = {
                    ssr: `${site}-${selectedSafra}-${rev}`,
                    siteId: site, 
                    safra: selectedSafra, 
                    revisao: rev, 
                    secao: item.grp, 
                    descricao: item.desc,
                    codigo: item.cod, 
                    unidade: item.unid,
                    jan: 0.0,
                    fev: 0.0,
                    mar: 0.0,
                    abr: 0.0,
                    mai: 0.0,
                    jun: 0.0,
                    jul: 0.0,
                    ago: 0.0,
                    set: 0.0,
                    out: 0.0,
                    nov: 0.0,
                    dez: 0.0,
                    acu: 0.0, 
                    input: !item.ipt ? null : item.ipt}
                    ps.push(newItem)
            }
        }
        console.log('User', user.email)
        await newPS(ps, user.email)
        let allPs = await resumeAllPS(site)
        setAllPsData(allPs)
    }

  return (
    <>
     <div style={{fontSize: '1.6vh', textAlign: 'left', color: 'black', fontWeight: 'bold', alignSelf: 'left', paddingLeft: '2vh', paddingTop: '1vh'}}>CRIAR NOVO PLANO</div>
    <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '2vh'}}>
       
       <div style={{textAlign: 'left', marginRight: '3vh', paddingBottom: '2vh'}}>
      <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>SAFRA:</label>
      <select id="comboBox" value={selectedSafra} onChange={handleChangeSafra} style={{color: 'black'}}>
        {listaSafras.map((item) => {
            return (
                <option value={item}>{item}</option>
            )
        })}
      </select>
    </div>
   
    <div style={{textAlign: 'left',marginRight: '2vh'}}>
    <label style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>OPÇÃO:</label>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{fontSize: '1.8vh', marginRight: '1vh'}}>
        <input
          type="radio"
          id="option1"
          name="options"
          value="opcao1"
          checked={selectedOption === 'opcao1'}
          onChange={handleChangeOption}
          
        />
        <label htmlFor="option1">Revisão</label>
      </div>
      <div style={{fontSize: '1.8vh', marginRight: '1vh'}}>
        <input
          type="radio"
          id="option2"
          name="options"
          value="opcao2"
          checked={selectedOption === 'opcao2'}
          onChange={handleChangeOption}
          style={{color: 'red'}}
        />
        <label htmlFor="option2">Estudo</label>
      </div>
      </div> 
    </div>
    {selectedOption === 'opcao1' && 
     <div style={{textAlign: 'left', marginRight: '3vh'}}>
     <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>REVISÃO:</label>
     <select id="comboBox" value={selectedRev} onChange={handleChangeRev} style={{color: 'black'}}>
       {listaRevs.map((item) => {
           return (
               <option value={item}>{item}</option>
           )
       })}
     </select>
   </div>
    }

{selectedOption === 'opcao2' && 
     <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', marginRight: '3vh', marginTop: '1vh', width: '30vh'}}>
     <label  style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>NOME:</label>
     <input id="" value={revName} onChange={handleChangeName} style={{padding: '0.4vh', border: '1px solid lightGray'}}/>
   </div>
    }
    <button style={{height: '50%', alignSelf: 'center'}} onClick={criarPS}>OK</button>
</div>
<ModalAlert show={showModal} onClose={handleCloseModal} title="Alerta">
<p>Plano já Existe!!!</p>

</ModalAlert>
</>
  )
  
}