const listaPS = ['planoSafra', 'compRevisoes', 'plano10Anos']
const listBME = ['extracao', 'tratAcucar', 'tratEtanol', 'filtLodo', 'evaporacao', 'fermentacao', 'destilaria', 'flotador', 'fabrica', 'vapor', 'balHidrico' ]
const listaAN = ['precos', 'analiseProducao', 'analiseCenarios']
const listaSettings = ['newUser']
const listaDNECods = ['dnePtExportada', 'dneEnergiaExportada', 'dneProdVapor', 'dneProdTotalVapor', 'dneRendCaldeira', 'dneConsBagaco', 'dneConsTotalBagaco']
let inputCods = ['psAtrPcts', 'psAtrDigestor', 'psFibraCana', 'psFibraBagaco', 'psUptimeGeral',
    'psDiasSafra', 'psMoagemTotal', 'psMixAcucar', 'psPerdaLavavem', 'psPerdaBagaco', 'psPerdaTorta', 
    'psPerdaMultijato', 'psPerdaFermentacao', 'psPerdaResiduaria', 'psPerdaIndeterminada', 'psRecSJM', 
    'psRitStab', 'psRitArt', 'psProdAcucarTotalSC', 'psMixProdAcucarVHP', 'psProdEtanolTotalHid', 
    'psProdEtanolAnidro', 'psProdEtanolNeutro', 'psRGD', 'psEnergiaExportada', 'psEnergiaGerada', 'psEnergiaConsumidaInd', 'psEnergiaConsumidaAgr',
    'psUpTimeVapor', 'psProdVapor', 'psConsBagacao', 'psVendaBagaco', 'psAjusteEstoqueBag',  'psProdVinhacapEtanol','psProdTortaTc']


module.exports = {
    listaPS,
    listBME,
    listaAN,
    listaDNECods,
    inputCods,
    listaSettings
}