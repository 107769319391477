import TenYearChartDataSEL from '../usSantaElisa/Plano Safra/TenYearChartDataSEL'
import TenYearChartsDataUBT from '../usBatatais/Plano Safra/TenYearChartsDataUBT'
import TenYearChartsDataSON from '../usSonora/Plano Safra/TenYearChartsDataSON'
import TenYearChartsDataUMA from '../usMonteAlegre/Plano Safra/TenYearChartsDataUMA'

export async function get10YearChartData(site){
    
    switch (site){
        case 'usSantaElisa': return TenYearChartDataSEL
        case 'usBatatais': return TenYearChartsDataUBT
        case 'usSonora': return TenYearChartsDataSON
        case 'usMonteAlegre': return TenYearChartsDataUMA
        default: return null
    }
}