import { useState, useContext, useEffect } from 'react'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import styles from './MenuLateral.module.css'
import { IoChevronForwardOutline, IoChevronDown, IoAlertCircleSharp } from "react-icons/io5";
import GlobalStateContext from '../GlobalStateContext'
import { getAlertList } from '../../Funcoes/GetAletrsList';
import { listaAN, listaPS, listBME, listaSettings } from '../../Resources/Constantes';
import { NavLink} from 'react-router-dom'
import { getLogo } from '../../BME/Unidades/Geral/getLogo';
import { useNavigate } from 'react-router-dom';
import { getVersion } from '../../BME/Unidades/Geral/getVersion';

function MenuLateral() {
  // const listBME = ['resumoProd', 'extracao', 'tratAcucar', 'tratEtanol', 'filtLodo', 'evaporacao', 'fermentacao', 'destilaria', 'fabrica', 'vapor', 'balHidrico', 'alertas']
  // const listPS = ['planoSafra', 'comparativoPs', 'plano10Anos']
  const [showMenuBme, setShowMenuBme] = useState(true)
  const [showMenuPS, setShowMenuPS] = useState(false)
  const [showMenuAN, setShowMenuAN] = useState(false)
  const [showMenuSet, setShowMenuSet] = useState(false)
  const {area, setArea, setScreen, listAlerts, user, setlistAlerts, data, setMenu, site} = useContext(GlobalStateContext)

  const navigate = useNavigate();

  useEffect(() => {
    
    navigate('/')
    setArea('resumoProd')
  }, [user]);

  const showBME = () => {
    setShowMenuBme(!showMenuBme)
  }

  const showPS = () => {
    setShowMenuPS(!showMenuPS)
  }

  const showAN = () => {
    setShowMenuAN(!showMenuAN)
  }

  const showSet = () => {
    setShowMenuSet(!showMenuSet)
  }

  const handleMenuChanged = (item) => {
    setArea('blank')

    setArea(item)
    
    
    if (item === 'resumoProd'){
      setScreen('fluxos')
    }
    

    if (listBME.includes(item) || item === 'resumoProd'){
      setMenu('bme')
      setScreen('fluxograma')
    }else if (listaPS.includes(item)){
      setMenu('ps')
    }else{
      setMenu('analises')
      setScreen('impBoletim')
    }
  }

  useEffect(() => {
    setlistAlerts(getAlertList(data))
  }, [data]);

  const anProdList = ['usSantaElisa', 'usBatatais']
  const flotadorList = ['usSonora']

  return (
    <div >
      
      <div className={styles.menu_Logo}>
        <img className={styles.logo_Costumer} src={getLogo(site)} alt="" />
      </div>
      <div className={styles.menu_Version}>{getVersion(site)}</div>
      
      <div className='menuLateral'>
          <div onClick={showBME} className={styles.menu_Head}>
              BALANÇO DE MASSA E ENERGIA
              {showMenuBme ? 
              <IoChevronDown className={styles.icon_Head}/> :
              <IoChevronForwardOutline className={styles.icon_Head}/>
              }
          </div>
          
          <div className={!showMenuBme ? styles.show_menuBme : ''} key={'menuBME'}>
          <nav>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
            <NavLink className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
                  to='/resumoProd' onClick={() => handleMenuChanged('resumoProd')}>Resumo de Produção</NavLink>
            </div>
            {listBME
            .map((item, index) => {
              if (item !== 'flotador' || (item === 'flotador' && flotadorList.includes(site))){

                return (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} key={index}>
                    <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} 
                    to={'/'+item+'/fluxograma'} onClick={() => handleMenuChanged(item)} 
                    key={item}>{getTitleFromArea(item)}</NavLink>
                    
                  
                    </div>
                )

              }
              
            })} 

  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
      <NavLink 
          className={({isActive}) => (isActive ? styles.sel_Item : styles.unsel_Item)} 
          to='/alertas' 
          onClick={() => handleMenuChanged('alertas')}
          style={{position: 'relative'}} // Definindo o NavLink como referência para o posicionamento absoluto
      >
          Alertas
          {listAlerts && listAlerts?.length > 0 &&
          <div style={{
            display: 'flex', position: 'absolute', justifyContent: 'center',  alignItems: 'center', 
            color: 'white',  top: '0.7vh', right: '1vh',  width: '2.8vh',  height: '2.8vh',  
            borderRadius: '50%', backgroundColor: 'red', fontSize: '1.6vh', fontWeight: 'bold'
        }}>{listAlerts?.length}</div>}

      </NavLink>
  </div>

            {/* {(user?.profile === 'developer') &&  */}
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <NavLink className={area === 'limites' ? styles.sel_Item : styles.unsel_Item} to={'/limites'} onClick={() => handleMenuChanged('limites')}>Limites</NavLink>
              </div>
            {/* } */}

          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <NavLink className={area === 'toPrint' ? styles.sel_Item : styles.unsel_Item} to={'/print'} onClick={() => handleMenuChanged('toPrint')}>Imprimir</NavLink>
              </div> */}
            </nav>
          </div>
          
    
        <div>
          <div onClick={showPS} className={styles.menu_Head}>
              PLANEJAMENTO SAFRA
              {showMenuPS ? 
              <IoChevronDown className={styles.icon_Head}/> :
              <IoChevronForwardOutline className={styles.icon_Head}/>
              }
          </div>
          
          <div className={!showMenuPS ? styles.show_menuBme : ''} key={'menuPS'}>
          <nav>
            {listaPS.map((item, index) => {
              return (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} key={index}>
                  <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/'+item} onClick={() => handleMenuChanged(item)} >{getTitleFromArea(item)}</NavLink>
                    {listAlerts?.length > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {item === 'alertas' ? <p className={styles.p_Item} 
                    style={{color: 'red', fontWeight: 'bold'}}>
                      {listAlerts?.length}</p> : <></>}
                      {item === 'alertas' ? <IoAlertCircleSharp  style={{color: 'red', fontWeight: 'bold', 
                      marginTop: '0.5vh', fontSize: '2.5vh', marginRight: '1vh', marginLeft: '-0.5vh'}}/> : <></>}
                  </div>}
                    </div>
              )
            })}
            </nav>
          </div>
          
        </div>

        <div>
          <div onClick={showAN} className={styles.menu_Head}>
              ANÁLISES
              {showMenuAN ? 
              <IoChevronDown className={styles.icon_Head}/> :
              <IoChevronForwardOutline className={styles.icon_Head}/>
              }
          </div>
          
          <div className={!showMenuAN ? styles.show_menuBme : ''} key={'menuAn'}>
          <nav>
            {listaAN.map((item, index) => {
              if (!anProdList.includes(site) && item === 'analiseProducao'){
                return(<></>)
              }else{
                return (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} key={index}>
                  <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/'+item} onClick={() => handleMenuChanged(item)}>{getTitleFromArea(item)}</NavLink>
                    </div>
              )
              }
              
            })}
            </nav>
          </div>

          {user?.profile === 'developer' &&
          <div>
          <div onClick={showSet} className={styles.menu_Head}>
              <p>CONFIGURAÇÕES</p>
              {showMenuSet ? 
              <IoChevronDown className={styles.icon_Head}/> :
              <IoChevronForwardOutline className={styles.icon_Head}/>
              }
          </div>
          
          <div className={!showMenuSet ? styles.show_menuBme : ''} key={'menuAn'}>
          <nav>
            {listaSettings.map((item, index) => {
              return (
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} key={index}>
                  <NavLink className={area === item ? styles.sel_Item : styles.unsel_Item} to={'/newUser'} onClick={() => handleMenuChanged(item)} >Novo Usuário</NavLink>
                    </div>
              )
            })}
            </nav>
            
          </div> 
          </div>}
          
        </div>
      </div>
    </div>
    
  )
}

export default MenuLateral

